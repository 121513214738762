















































































import { Component, Vue } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { namespace } from "vuex-class";
import isEmpty from "lodash/isEmpty";

const AuthModule = namespace("auth");

@Component({
  components: {
    Validation,
  },
})
export default class Login extends Vue {
  public name = "Login";

  public username = "";
  public password = "";

  private supportLink = "https://support.fahrschulcockpit.de/portal/de/newticket?departmentId=338614000015453045&layoutId=338614000015458492";

  @AuthModule.Action("signIn")
  public signIn: any;

  @AuthModule.Getter("getError")
  public getError: any;

  @Validations()
  public validations(): Record<string, any> {
    return {
      username: { required },
      password: { required },
    };
  }

  public async onSubmit(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    await this.signIn({
      username: this.username,
      password: this.password,
      authClient: "FSC_WEB",
    });
  }

  public get hasError(): boolean {
    return !isEmpty(this.getError);
  }
}
